import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import tw, { css, styled } from 'twin.macro'
import { ReactComponent as Caret } from '../images/icon-caret-down-lg.svg'
import { global } from '../styles/global'
import GatsbyLink from './link'

const ColumnHeader = styled.div`
  ${tw`
    cursor-pointer flex justify-between items-center text-lg font-medium leading-subtitle
    tracking-subtitle text-secondary-500 lg:(text-base cursor-auto)
  `}
`

const Link = (props) => {
  return (
    <GatsbyLink
      style={[
        tw`text-2xl font-light text-white leading-h5 tracking-h4`,
        global`base.hover-underline`(tw`bg-white`, tw`h-px`),
      ]}
      {...props}
    />
  )
}

const MobileDivider = styled.div`
  left: calc((100vw - 48px) / -19.2);
  right: calc((100vw - 48px) / -19.2);
  ${tw`absolute bottom-0 h-px bg-white bg-opacity-20 lg:hidden`};
`

const Footer = ({
  logo,
  links,
  linkHeaders,
  legalLabel,
  legal,
  socialMediaLabel,
  socialMedia,
  globalSiteLabel,
  globalSite,
  copyright,
}) => {
  const [activeColumn, setActiveColumn] = useState(-1)
  return (
    <footer
      css={tw`relative bottom-0 flex flex-col w-full overflow-hidden -z-10 bg-primary-500 lg:sticky`}
    >
      <section css={tw`pt-14 lg:py-14`}>
        <nav css={[global`layout.container`, global`layout.grid`]}>
          {links &&
            links.map((linkGroup, i) => (
              <div
                key={linkHeaders[i]}
                css={[
                  tw`relative col-span-4 first-of-type:pt-0 py-6 md:col-span-8 lg:(py-0 mt-3 col-span-3 first-of-type:col-span-4) xl:(col-span-4 first-of-type:col-span-5)`,
                  activeColumn === i &&
                    css`
                      ul {
                        ${tw`max-h-screen`}
                      }
                    `,
                ]}
              >
                <ColumnHeader
                  onClick={() => (activeColumn === i ? setActiveColumn(-1) : setActiveColumn(i))}
                >
                  {linkHeaders[i]}
                  <Caret
                    css={[
                      tw`flex-none block text-white transition duration-300 ease-in-out fill-current stroke-0 lg:hidden`,
                      activeColumn === i &&
                        css`
                          ${tw`text-secondary-500`}
                          transform: scaleY(-1);
                        `,
                    ]}
                  />
                </ColumnHeader>
                <ul
                  css={tw`max-h-0 overflow-hidden transition-all duration-300 ease-linear flex flex-col lg:(max-h-full mt-5)`}
                >
                  {linkGroup.map((l) => (
                    <li
                      key={l.label}
                      css={tw`mb-4 first-of-type:mt-5 lg:(first-of-type:mt-1 mb-2)`}
                    >
                      <Link to={l.link}>{l.label}</Link>
                    </li>
                  ))}
                </ul>
                <MobileDivider />
              </div>
            ))}
          <GatsbyLink
            to="/"
            style={tw`flex items-start justify-start col-span-4 row-start-1 mb-14 md:col-span-8 lg:(mb-0 justify-end row-start-auto col-start-11 col-end-13) xl:(col-start-14 col-end-17)`}
          >
            <img
              css={[tw`w-full`, { maxWidth: '66px' }]}
              src={get(logo, 'url')}
              alt={get(logo, 'alt')}
              title={get(logo, 'title')}
            />
          </GatsbyLink>
        </nav>
      </section>
      <section css={[tw`lg:(border-t border-white border-opacity-20 py-6)`]}>
        <div css={[global`layout.container`, global`layout.grid`]}>
          {globalSiteLabel && <div
            css={[
              tw`flex flex-col col-span-2 py-8 md:col-span-4 lg:(items-start py-0 col-span-3 col-start-1)`,
            ]}
          >
            <ColumnHeader css={tw`text-base`}>{globalSiteLabel}</ColumnHeader>
            <GatsbyLink
              to={globalSite.link}
              style={[
                css`
                  ${tw`mt-1 text-2xl font-light lg:mt-3 text-accent-500 leading-md`}
                `,
                global`base.hover-underline`(tw`bg-accent-500`, tw`h-px`),
              ]}
            >
              {globalSite.label}
            </GatsbyLink>
          </div>}
          {socialMedia && (
            <div
              css={tw`flex justify-start col-span-2 py-8 md:col-span-4 lg:(py-0 col-span-3 col-start-1) xl:(col-span-3) `}
            >
              <div css={tw`flex flex-col items-start justify-start`}>
                <ColumnHeader css={tw`text-base`}>{socialMediaLabel}</ColumnHeader>
                <div css={tw`flex flex-row justify-between mt-4`}>
                  {socialMedia.map((s) => (
                    <GatsbyLink
                      key={s.title}
                      to={s.accountLink.link}
                      style={tw`mr-12 last-of-type:mr-0`}
                    >
                      <img
                        src={s.image.url}
                        title={s.title}
                        alt={s.alt}
                        loading="lazy"
                        css={tw`max-h-social-icon`}
                      />
                    </GatsbyLink>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <section css={tw`border-t border-white pt-5 pb-7 lg:(pt-6 pb-8) border-opacity-20`}>
        <div css={[global`layout.container`, global`layout.grid`]}>
          <div
            css={tw`flex flex-col col-span-4 mb-4 md:col-span-8 lg:(flex-row items-center col-span-12) xl:col-span-16`}
          >
            <ColumnHeader css={tw`text-base lg:mr-8`}>{legalLabel}</ColumnHeader>
            <div css={tw`mt-2 space-x-8 lg:mt-0`}>
              {legal.map((l, i) => (
                <Link key={i} to={l.link} css={tw`text-base font-normal`}>
                  {l.label}
                </Link>
              ))}
            </div>
          </div>
          <span
            css={tw`col-span-4 text-sm leading-4 text-white text-opacity-50 md:col-span-8 lg:col-span-12 xl:col-span-16`}
          >
            {copyright}
          </span>
        </div>
      </section>
    </footer>
  )
}

Footer.propTypes = {
  logo: PropTypes.object,
  links: PropTypes.any,
  linkHeaders: PropTypes.arrayOf(PropTypes.string),
  legalLabel: PropTypes.string,
  legal: PropTypes.arrayOf(PropTypes.object),
  socialMediaLabel: PropTypes.string,
  globalSiteLabel: PropTypes.string,
  socialMedia: PropTypes.arrayOf(PropTypes.object),
  globalSite: PropTypes.object,
  copyright: PropTypes.string,
}

export default Footer
